import axios from 'axios';
import { useEffect, useState } from 'react';
import { capitalize, isEmptyObj } from 'utils';

export const useRequest = ({
  endpoint,
  method,
  headers,
  model,
  options,
  autoFetch,
  initialValue
}) => {
  const [response, setResponse] = useState(initialValue);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  const request = (params) => {
    const { query, param, body, onFinish, onError, fn } = params || {};
    setLoading(true);
    const formData = new FormData()
    const haveFile = body && Object.values(body).find(field => field instanceof File)

    if(haveFile) {
      for(const key in body) {
        if(body[key]) {
          formData.append(key, body[key])
        }
      }
    }
    
    return axios({
      method,
      url: `${endpoint}${param ? `/${param}` : ''}${
        !isEmptyObj(query) ? `?${new URLSearchParams(query)}` : ''
      }`,
      data: (!haveFile && body) || (haveFile && formData)  || undefined,
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-Type': haveFile ? 'multipart/form-data' : undefined
      },
      ...options,
    })
      .then((res) => {
        const { data } = res;
        setResponse(data);
        fn?.(data);
        onFinish?.();
      })
      .catch((err) => {
        setError(err);
        onError?.();
      })
      .finally(() => setTimeout(() => setLoading(false), 100));
  };

  useEffect(() => {
    if(autoFetch) {
      setTimeout(() => request(), 500)
    }
  }, [])

  return {
    [`fetch${capitalize(model)}`]: request,
    [model]: response,
    [`set${capitalize(model)}`]: setResponse,
    [`${model}Error`]: error,
    [`${model}Loading`]: loading,
  };
};