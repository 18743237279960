import { LoginProvider, PathProvider } from "contexts"
import { SystemRouter } from 'core'

export const App = () => (
  <LoginProvider>
    <PathProvider>
      <SystemRouter />
    </PathProvider>
  </LoginProvider>
)
