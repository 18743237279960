import React, { createContext, useContext, useState } from "react"
import { PropTypes } from "prop-types"

export const PathContext = createContext({})

export const usePathContext = () => useContext(PathContext)

export const PathProvider = ({ children }) => {
  const [path, setPath] = useState({
    menuText: '...',
    menu: '...',
    breadcrumbs: [{
      text: '...',
      url: '/'
    }],
    path: '/'
  })

  return (
    <PathContext.Provider
      value={{
        path,
        setPath
      }}
    >
      {children}
    </PathContext.Provider>
  )
}

PathProvider.prototype = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}