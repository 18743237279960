import { Menu } from "components"
import { useLoginContext } from "contexts"
import { useEffect } from "react"
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom"
import { home, signup, signin, account } from "routes"

export const router = [home, account, signup, signin]

const Redirect = ({ to }) => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(to)
  }, [])

  return <></>
}

export const SystemRouter = () => {
  const { isLoginValid } = useLoginContext()
  return (
    <Router>
      <Routes>
        {router.map((mainRoute, idxMainRoute) => (
          <Route key={`mainRoute-${idxMainRoute}`} path={mainRoute.path}>
            {
              mainRoute.urls.map((route, idxRoute) => (
                ((mainRoute.authenticated && isLoginValid) ||
                (mainRoute.authenticated === false && !isLoginValid) ||
                (mainRoute.authenticated === undefined))
                  ? <Route
                      key={`route-${idxMainRoute}-${idxRoute}`}
                      path={route.path === '/' ? '' : route.path}
                      element={
                        ((route.authenticated && isLoginValid) ||
                        (route.authenticated === false && !isLoginValid) ||
                        (route.authenticated === undefined))
                          ? mainRoute.showMenu === false ? route.component : <Menu>{route.component}</Menu>
                          : route.authenticated === false && isLoginValid
                            ? <Redirect to={'/'} />
                            : <Redirect to={'/signin'} />
                      } />
                  : mainRoute.authenticated === false && isLoginValid
                    ? <>
                      <Route key={`route-${idxMainRoute}-${idxRoute}`} path="" element={<Redirect to={'/'} />} />
                      <Route key={`route-${idxMainRoute}-${idxRoute}`} path="*" element={<Redirect to={'/'} />} />
                    </>
                    : <>
                      <Route key={`route-${idxMainRoute}-${idxRoute}`} path="" element={<Redirect to={'/signin'} />} />
                      <Route key={`route-${idxMainRoute}-${idxRoute}`} path="*" element={<Redirect to={'/signin'} />} />
                    </>
              ))
            }
          </Route>
        ))}
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </Router>
  )
}
