import { useEffect } from "react"
import { usePathContext } from "contexts"
import { router } from "core"
import { useNavigate } from "react-router-dom"

export const usePath = () => {
  const navigate = useNavigate()
  const { setPath } = usePathContext()

  useEffect(() => {
    const urlArr = window.location.pathname.split('/').filter(i => i)
    
    goToMenu(urlArr[0] ? urlArr : '/')
  }, [])

  const goToMenu = (urlArr) => {
    let haveToNavigate = true
    let item = router.find(route => route.path === urlArr[0])
    const menuText = item.menu
    const url = [item.path]
    const breadcrumbs = [{
      text: item.menu,
      url: [item.path]
    }]
    
    if(urlArr[1]) {
      item = item.urls.find(route => route.path === urlArr[1])

      if(item) {
        url.push(item.path)
        breadcrumbs.push({
          text: item.menu,
          url
        })
      } else {
        haveToNavigate = false
      }
    }

    setPath({
      menuText,
      breadcrumbs,
      ...item
    })

    if(haveToNavigate) {
      navigate(`/${url.join('/')}`.replace('//', '/'))
    }
  }

  return { goToMenu }
}
